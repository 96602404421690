import React from 'react';
import styled from 'styled-components';

// Stili për footer
const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const FooterText = styled.p`
  margin: 0;
  font-size: 1rem;
`;

const FooterLink = styled.a`
  color: 	#00BFFF;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>
        &copy; {new Date().getFullYear()} Shtëpia Botuese Jonalda. Të gjitha të drejtat e rezervuara.{' '}
        <FooterLink href="https://absolutsystem.com/">Absolut Technology System</FooterLink>
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
